import { Post } from 'layouts';
import { GetStaticPaths, GetStaticProps } from 'next';
import { createApolloClient } from 'lib/graphql/apollo';
import { POST } from 'lib/graphql/queries/post';
import { Post as PostType } from 'types/post';
import { load } from 'cheerio';
import { encode } from 'url-encode-decode';
import { reportError } from 'lib/errors';
import { HOME_POSTS } from 'lib/graphql/queries/home';
import { HomePosts } from 'types/home';

export const getStaticProps: GetStaticProps<{ slug: string }> = async ({ params }) => {
    /**
     * Searches content body html for at least one matching
     * blockquote with identifying class name.
     */
    const hasClass = (html: string, idClassName: string) => {
        const $ = load(html);
        return $('blockquote').hasClass(idClassName);
    };

    const client = createApolloClient();

    let { slug } = params;

    slug = encode(slug as string);

    const props: any = {
        slug,
        hasInstagramEmbed: false,
        hasTwitterEmbed: false,
    };

    try {
        const { data, errors, loading, networkStatus } = await client.query<{
            publishedPost: PostType;
        }>({
            query: POST,
            variables: {
                slug,
            },
        });

        const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
        props.isLoading = isLoading;

        if ((errors && errors.length) || !data) {
            props.error = errors[0];
        } else if (data && data.publishedPost) {
            props.post = data.publishedPost;
            props.hasInstagramEmbed = hasClass(
                data.publishedPost.content,
                'instagram-media',
            );
            props.hasTwitterEmbed = hasClass(data.publishedPost.content, 'twitter-tweet');
        }
    } catch (err) {
        //console.error(err);
        reportError(err, {
            metaData: {
                operation: 'graphql query publishedPost',
                slug,
                mode: 'getStaticProps',
            },
            severity: 'warning',
        });
    }

    return {
        props,
        revalidate: 180, // 2 minutes
    };
};

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
    const client = createApolloClient();

    const { data } = await client.query<HomePosts>({
        query: HOME_POSTS,
        variables: {
            limit: 9,
            skip: 0,
        },
    });

    const posts = [...Object.values(data.featuredPosts), ...data.publishedPosts];

    const paths = posts
        .filter(({ slug }) => typeof slug === 'string')
        .map(({ slug }) => ({
            params: { slug },
        }));

    return { paths, fallback: true };
};

export default Post;
